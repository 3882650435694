import { CART_TYPES } from '../types'
import { isBrowser } from 'utils'

const keyStorage = 'cart:qlue-saas-website'
const getCartFromStorage = isBrowser ? JSON.parse(window.localStorage.getItem(keyStorage)) : {}

if(getCartFromStorage) delete getCartFromStorage.vat


const initialState = {
  referralCode    : '',
  discount        : 0,
  annuallySave    : 20,
  vat             : 11,
  packages        : [],
  paymentPeriod   : {},
  isCustom        : false,
  currency        : 'USD',
  country         : 'Singapore',
  currencies      : [],
  selectedPackage : {
    name                    : '',
    products                : {},
    account_packages_prices : []
  },
  ...getCartFromStorage
}


export function cartReducers(state = initialState, { type, ...payload }) {
  switch (type) {
    case CART_TYPES.SET_REFERRAL_CODE:
      state = {
        ...state,
        referralCode: payload.referralCode
      }
      break
    case CART_TYPES.SET_DISCOUNT:
      state = {
        ...state,
        discount: payload.discount
      }
      break
    case CART_TYPES.SET_CURRENCIES:
      state = {
        ...state,
        currencies: payload.currencies
      }
      break
    case CART_TYPES.SET_CURRENCY:
      state = {
        ...state,
        currency: payload.currency
      }
      break
    case CART_TYPES.TYPE_SET_PACKAGES:
      state = { ...state, packages: payload.packages }
      break
    case CART_TYPES.SET_SELECTED_PACKAGE:
      state = {
        ...state,
        selectedPackage: { ...state.selectedPackage, ...payload.selectedPackage }
      }
      break
    case CART_TYPES.CHANGE_PAYMENT_PERIOD:
      state = { ...state, paymentPeriod: payload.paymentPeriod }
      break
    case CART_TYPES.SET_CUSTOM_PACKAGE:
      state = { ...state, isCustom: payload.isCustom }
      break
    case CART_TYPES.REMOVE_PRODUCT: {
      const { selectedPackage } = state
      const copyProducts = { ...selectedPackage.products }
      delete copyProducts[payload.productId]

      state = {
        ...state,
        selectedPackage: {
          ...selectedPackage,
          products: copyProducts
        }
      }
      break
    }
    case CART_TYPES.CHANGE_QTY_PRODUCT: {
      const { selectedPackage } = state
      const { num, productId } = payload
      const copyProducts = { ...selectedPackage.products }
      const detailProduct = { ...copyProducts[productId] }
 
      if(num) detailProduct.product_account_package.quantity = num

      state = {
        ...state,
        selectedPackage: {
          ...selectedPackage,
          products: {
            ...copyProducts,
            [productId]: { ...detailProduct }
          }
        }
      }

      break
    }
    default:
      break
  }

  const newState = {
    ...state,
    get isAnnually() {
      return state.paymentPeriod?.name?.search(/annually/gi) > -1
    },
    get subTotal() {
      const newPrice = { annually: 0, monthly: 0 }

      if (!state.isCustom) {
        state.selectedPackage.account_packages_prices.forEach((price) => {
          const regex = new RegExp(this.detailCurrency.currency, 'gi')

          if (price.currency.name.search(regex) > -1) {
            newPrice.monthly = price.price_monthly || 0
            newPrice.annually = price.price_annually || 0
          }
        })
      } else {
        Object.keys(state.selectedPackage.products)
          .map((key) => state.selectedPackage.products[key])
          .forEach((product) => {
            const productPrice = { annually: 0, monthly: 0 }

            // get price according to currency
            product.product_prices?.forEach((price) => {
              const regex = new RegExp(this.detailCurrency.currency, 'gi')

              if (price.currency.name.search(regex) > -1) {
                productPrice.annually = price.price_annually
                productPrice.monthly = price.price_monthly
              }
            })

            newPrice.monthly += productPrice.monthly * product.product_account_package.quantity
            newPrice.annually += productPrice.annually * product.product_account_package.quantity
          })
      }

      return this.isAnnually ? newPrice.annually : newPrice.monthly
    },
    get subTotalMonthlyForCustom() {
      let price = 0
      const keysProduct = Object.keys(state.selectedPackage.products)

      price = keysProduct
        .map((key) => state.selectedPackage.products[key])
        .reduce((acc, product) => {
          let amount = 0

          // get price according to currency
          product.product_prices?.forEach((price) => {
            const regex = new RegExp(this.detailCurrency.currency, 'gi')

            if (price.currency.name.search(regex) > -1) amount = price.price_monthly
          })

          acc += amount * product.product_account_package.quantity

          return acc
        }, 0)

      return price
    },
    get detailCurrency() {
      let currency = { currency: state.currency }
      for (let i = 0; i < state.currencies.length; i++) {
        const currencyItem = state.currencies[i]
        const regex = new RegExp(state.currency, 'gi')

        // find currency based on location
        if (currencyItem.currency.search(regex) > -1) {
          currency = currencyItem
          break
        }

        // set the default data
        if (currencyItem.currency.search(/usd/gi) > -1) currency = currencyItem
      }

      return currency
    }
  }

  if (isBrowser) {
    const isPricingPage = location.href.search(/pricing/gi) > -1
    if (isPricingPage) localStorage.removeItem(keyStorage)
    else localStorage.setItem(keyStorage, JSON.stringify(newState))
  }

  return newState
}
