import { TRANSACTION_TYPES } from '../types'
import { isBrowser } from 'utils'

const keyStorage = 'transaction:qlue-saas-website'
const getTransactionFromStorage = isBrowser
  ? JSON.parse(window.localStorage.getItem(keyStorage))
  : {}

const initialState = {
  id: getTransactionFromStorage ? getTransactionFromStorage.id : '',
  invoice_no: getTransactionFromStorage ? getTransactionFromStorage.invoice_no : '',
  amount: getTransactionFromStorage ? getTransactionFromStorage.amount : '',
  billing_end_date: getTransactionFromStorage ? getTransactionFromStorage.billing_end_date : '',
  card_name: getTransactionFromStorage ? getTransactionFromStorage.card_name : '',
  card_number: getTransactionFromStorage ? getTransactionFromStorage.card_number : '',
  due_date: getTransactionFromStorage ? getTransactionFromStorage.due_date : ''
}

export function transactionReducers(state = initialState, { type, data }) {
  switch (type) {
    case TRANSACTION_TYPES.SET_INVOICE:
      state = {
        ...state,
        ...data
      }
      if (isBrowser) {
        localStorage.setItem(keyStorage, JSON.stringify(state))
      }
      break
    default:
      break
  }

  return state
}
