import { PAYMENT_TYPES } from '../types'
import { isBrowser } from 'utils'

const keyStorage = 'payment:qlue-saas-website'
const getPaymentFromStorage = isBrowser ? JSON.parse(window.localStorage.getItem(keyStorage)) : ''

const initialState = {
  paymentMethod : getPaymentFromStorage ? getPaymentFromStorage.paymentMethod : '',
  selectedBank  : {
    id          : getPaymentFromStorage ? getPaymentFromStorage.selectedBank?.id : '',
    name        : getPaymentFromStorage ? getPaymentFromStorage.selectedBank?.name : '',
    brand       : getPaymentFromStorage ? getPaymentFromStorage.selectedBank?.brand : '',
    brand_alias : getPaymentFromStorage ? getPaymentFromStorage.selectedBank?.brand_alias : 'bni',
    code        : getPaymentFromStorage ? getPaymentFromStorage.selectedBank?.code : '',
    image_url   : getPaymentFromStorage ? getPaymentFromStorage.selectedBank?.image_url : ''
  }
}

export function paymentReducers(state = initialState, { type, ...payload }) {
  switch (type) {
    case PAYMENT_TYPES.SET_PAYMENT_METHOD:
      state = {
        ...state,
        paymentMethod: payload.paymentMethod
      }
      if (isBrowser) {
        localStorage.setItem(keyStorage, JSON.stringify(state))
      }
      break
    case PAYMENT_TYPES.SET_BANK:
      state = {
        ...state,
        selectedBank: payload.selectedBank
      }
      if (isBrowser) {
        localStorage.setItem(keyStorage, JSON.stringify(state))
      }
      break
    default:
      break
  }

  return state
}
