const API = process.env.GATSBY_API_URL

const PostActivationAffiliate = `${API}/auth/activation-affiliate`
const PostResendActivationAffiliate = `${API}/auth/resend-activation-affiliate`
const GetCheckExpiredAffiliate = `${API}/auth/check-expired-affiliate`
const PostLoginAffiliate = `${API}/auth/login-affiliate`
const GetAuthRefreshToken = `${API}/auth/refresh-affiliate`
const ForgotPasswordAffiliate = `${API}/affiliates/password/forget/email`

export default {
  PostActivationAffiliate,
  PostResendActivationAffiliate,
  GetCheckExpiredAffiliate,
  PostLoginAffiliate,
  GetAuthRefreshToken,
  ForgotPasswordAffiliate
}
