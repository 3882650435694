const API = process.env.GATSBY_API_URL

const TransactionSummary = `${API}/affiliates/dashboard/transaction-summary`
const TransactionClients =`${API}/affiliates/dashboard/transaction-clients`
const TransactionList = `${API}/affiliates/dashboard/transaction`

export default {
  TransactionSummary,
  TransactionClients,
  TransactionList
}