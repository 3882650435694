import { SET_TOKEN_DASHBOARD } from '../types'

const initialState = {
  token     : null,
  isLoading : true
}

export function tokenDashboard(state = initialState, { type, token, isLoading }) {
  switch (type) {
    case SET_TOKEN_DASHBOARD:
      return { token, isLoading }
    default:
      return state
  }
}
