const API = process.env.GATSBY_API_URL

const PostRegister = `${API}/affiliates/register`
const PostCompleteRegister = `${API}/affiliates/dashboard/complete-register`
const PostValidateRegister = `${API}/affiliates/validate-register`

export default {
  PostRegister,
  PostCompleteRegister,
  PostValidateRegister
}
