import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";

import thunk from "./middlewares/thunk";
import apiMiddleware from "./middlewares/apiMiddleware";
import rootReducers from "./reducers";
import { isBrowser } from "utils";

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
const middlewares = [thunk, apiMiddleware];
const enhancers = [];
const logger = createLogger({ collapsed: true });

if (activeEnv === "development") middlewares.push(logger);

const devToolsExtension = isBrowser ? window.__REDUX_DEVTOOLS_EXTENSION__ : null;
if (typeof devToolsExtension === "function") enhancers.push(devToolsExtension());

const store = createStore(rootReducers, {}, applyMiddleware(...middlewares));

export default store;
