const API = process.env.GATSBY_API_URL

const PostClientList = `${API}/affiliates/dashboard/clients`
const GetClientCurrentBilling = (id) => `${API}/affiliates/dashboard/current-billing/${id}`
const PostClientBillingHistoryList = (id) => `${API}/affiliates/dashboard/billing-history/${id}`
const GetRenewSubscriptionBtn = (id) => `${API}/affiliates/dashboard/renew-subscription-button/${id}`

export default {
  PostClientList,
  GetClientCurrentBilling,
  PostClientBillingHistoryList,
  GetRenewSubscriptionBtn
}
