import {
  getInitReducerState as init,
  getFailedReducerState as fail,
  getSuccessReducerState as success,
  getRequestReducerState as request,
} from "utils/reducerUtils";

import { REQUEST_REGISTER, SUCCESS_REGISTER, FAILURE_REGISTER } from "./constant";

const initialState = {
  register: init(),
};

const registerReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case "CHANGE_DEFAULT": {
      return {
        register: {
          isLoaded: false,
          isLoading: false,
          hasError: false,
          error: null,
        },
      };
    }
    case REQUEST_REGISTER: {
      return {
        ...state,
        register: {
          ...request(),
        },
      };
    }
    case SUCCESS_REGISTER: {
      return {
        ...state,
        register: {
          ...success({ ...payload.payload.lead }),
        },
      };
    }
    case FAILURE_REGISTER: {
      const { response } = payload;
      return {
        ...state,
        register: {
          ...fail(response.data.message),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default registerReducer;
