export const decryptToken = (token) => {
  // 1. convert to array
  const tokenArr = token.split('')

  // 2. convert to list of ASCII
  const listASCII = tokenArr.map((alphabet) => alphabet.charCodeAt(0))

  // 3. generate real list ASCII -> realASCII = currentASCII - index%2
  const realListASCII = listASCII.map((code, index) => code - (index % 2))

  // 4. convert real list ASCII to list alphabet
  const listAlphabet = realListASCII.map((code) => String.fromCharCode(code))

  // 5. reverse array
  const reverseListAlphabet = listAlphabet.reverse()

  // 6. convert listAlphabet to formatted string token
  const decryptedToken = reverseListAlphabet.join('')

  return decryptedToken
}  