import React, { 
  useContext, 
  useEffect, 
  useState, 
  useCallback 
} from 'react'
import { AffiliateAnalyticsURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { isBrowser } from 'utils'

/**
 *
 * Access values via useAuth() hooks in other components
 **/

export const AFFILIATE_USER = 'affiliate-user'
export const TOKEN_NAME = 'affiliate_access_token'
export const REFRESH_TOKEN_NAME = 'affiliate_refresh_token'
export const LOGOUT = 'affiliate_logout'

// get value of cookie
export function getCookieValue(name) {
  const getCookie = isBrowser ? document.cookie.split('; ').find((row) => row.startsWith(name)) : ''

  if (getCookie) return getCookie.split('=')[1]

  return null
}

export const saveToken = ({ access_token, refresh_token }) => {
  document.cookie = `${TOKEN_NAME}=${access_token}` + '; path=/'
  document.cookie = `${REFRESH_TOKEN_NAME}=${refresh_token}` + '; path=/'
}

const defaultContext = {
  isLoggedIn    : false,
  isAuthLoading : true,
  customer      : {},
  onLogin       : () => {},
  user          : {}
}

const AuthContext = React.createContext(defaultContext)

export const AuthProvider = ({ children, location }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [user, setUser] = useState({})

  const fetchAnalytics = useCallback(async () => {
    setIsAuthLoading(true)
    await AffiliateAPI.get(AffiliateAnalyticsURL.GetAnalytics)
      .then((response) => {
        setUser(response.data)
        setIsLoggedIn(true)
        setIsAuthLoading(false)
        localStorage.setItem(AFFILIATE_USER, JSON.stringify(response.data))
      })
      .catch(() => {
        setIsLoggedIn(false)
        setIsAuthLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchAnalytics()

    // to listen to a logout event happening in local/session storage
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
    }
  }, [fetchAnalytics, isLoggedIn])

  /**
   * Call this function whenever success on Customer login
   */
  const onLogin = () => {
    setIsLoggedIn(true)
    setIsAuthLoading(false)
  }

  /**
   * Call this function whenever success on Customer logout
   */
  const onLogout = useCallback(() => {
    // delete cookies token
    document.cookie = TOKEN_NAME + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
    document.cookie = REFRESH_TOKEN_NAME + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'

    // remove data profile
    localStorage.removeItem(AFFILIATE_USER)

    // To trigger the event listener to execute the redirect.
    localStorage.setItem(LOGOUT, Date.now())
    setIsLoggedIn(defaultContext.isLoggedIn)
    setIsAuthLoading(false)
  }, [])

  // Redirect user in all open tabs when onLogout is executed.
  const syncLogout = (event) => {
    if (event.key === 'affiliate_logout') {
      setIsLoggedIn(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        AFFILIATE_USER,
        isLoggedIn,
        isAuthLoading,
        onLogin,
        onLogout,
        saveToken,
        setUser,
        fetchAnalytics
      }}
    >
      { children }
    </AuthContext.Provider>
  )
}

/**
 * Hook to be used in components to return auth context
 * @return context.isAuth - is customer logged in
 */
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
