/* eslint-disable semi */
const React = require('react')
const WrapperPage = require('./src/components/WrapperPage').default
const { Provider } = require('./src/utils/useLanguage')

// --- Qontak Integration ---
;(function () {
  const firstScript = document.createElement('script')
  const firstTag = document.getElementsByTagName('script')[0]
  const secondScript = document.createElement('script')

  firstScript.type = 'text/javascript'
  firstScript.src = 'https://webchat.qontak.com/qchatInitialize.js'
  firstScript.async = true

  secondScript.type = 'text/javascript'
  secondScript.src = 'https://webchat.qontak.com/js/app.js'
  secondScript.async = true

  document.head.prepend(firstScript)
  document.head.prepend(secondScript)

  firstScript.onload = firstScript.onreadystatechange = function () {
    window.qchatInitialize({ id: process.env.GATSBY_QONTAK_ID, code: process.env.GATSBY_QONTAK_CODE })
  }

  firstTag.parentNode.insertBefore(firstScript, firstTag)
})()
// --- Qontak Integration ---

exports.wrapRootElement = ({ element }) => (
  <Provider>
    <WrapperPage>{ element }</WrapperPage>
  </Provider>
)
