import axios from 'axios'
import { AffiliateAuthURL } from 'commons/API'
import { 
  getCookieValue, 
  saveToken,
  TOKEN_NAME, 
  REFRESH_TOKEN_NAME,
  AFFILIATE_USER
} from '../../../providers/auth-provider'


const instance = axios.create()

instance.interceptors.request.use((config) => {
  const token = getCookieValue(TOKEN_NAME)
  config.headers.authorization = token
  config.headers['Access-Control-Allow-Origin'] = '*'
  return config
})

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // if token expired
    if (error.response.status === 403 && error.response.config.url !== AffiliateAuthURL.PostLoginAffiliate) {
      const refreshToken = getCookieValue(REFRESH_TOKEN_NAME)
      // get new token
      return axios({
        url     : AffiliateAuthURL.GetAuthRefreshToken,
        method  : 'post',
        headers : {
          authorization: refreshToken
        }
      })
        .then(async (res) => {
          // update token
          saveToken(res.data) 

          // override token
          originalRequest.headers.authorization = res.data.access_token

          return await axios(originalRequest)
        })
    }
    else if (error.response.status === 401 && error.response.config.url !== AffiliateAuthURL.PostLoginAffiliate ) {
      // delete cookies token
      document.cookie = TOKEN_NAME +'=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
      document.cookie = REFRESH_TOKEN_NAME +'=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'

      // remove data profile
      localStorage.removeItem(AFFILIATE_USER)
    }

    else return Promise.reject(error)
  }
)

export default instance
