const API = process.env.GATSBY_API_URL

const BaseURL = `${API}/transactions`
const GetCurrencies = `${BaseURL}/currencies`
const PostPaymentMethod = `${BaseURL}/payment-methods`
const GetBankList = `${BaseURL}/banks`
const GetLatestPending = `${BaseURL}/latest-pending`
const PostPlaceOrderVA = `${BaseURL}/place-order-va`
const PostCreateSubscriptionCC = `${BaseURL}/create-subscription-cc`
const PostVAClientSecret = `${BaseURL}/va-client-secret`
const PostValidateRefCode = `${BaseURL}/referral-code`

export default {
  BaseURL,
  GetCurrencies,
  PostPaymentMethod,
  GetBankList,
  GetLatestPending,
  PostPlaceOrderVA,
  PostCreateSubscriptionCC,
  PostVAClientSecret,
  PostValidateRefCode
}

