// cart types
export const CART_TYPES = {
  TYPE_SET_PACKAGES     : 'TYPE_SET_PACKAGES',
  SET_CURRENCIES        : 'SET_CURRENCIES',
  SET_CURRENCY          : 'SET_CURRENCY',
  SET_SELECTED_PACKAGE  : 'SET_SELECTED_PACKAGE',
  CHANGE_PAYMENT_PERIOD : 'CHANGE_PAYMENT_PERIOD',
  SET_CUSTOM_PACKAGE    : 'SET_CUSTOM_PACKAGE',
  ADDING_GETTERS_VALUE  : 'ADDING_GETTERS_VALUE',
  REMOVE_PRODUCT        : 'REMOVE_PRODUCT',
  CHANGE_QTY_PRODUCT    : 'CHANGE_QTY_PRODUCT',
  SET_REFERRAL_CODE     : 'SET_REFERRAL_CODE',
  SET_DISCOUNT          : 'SET_DISCOUNT'
}

export const SET_TOKEN_DASHBOARD = 'SET_TOKEN_DASHBOARD'

export const PAYMENT_TYPES = {
  SET_PAYMENT_METHOD : 'SET_PAYMENT_METHOD',
  SET_BANK           : 'SET_BANK'
}

export const TRANSACTION_TYPES = {
  SET_INVOICE: 'SET_INVOICE'
}

