import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import enLang from 'intl/en.json'
import idLang from 'intl/id.json'
import jpLang from 'intl/jp.json'

export const LANGUAGE_SOURCE = {
  en: enLang,
  id: idLang,
  jp: jpLang
}

const Context = createContext()

const propTypes = {
  children: PropTypes.element
}

export function Provider({ children }) {
  const [lang, setLang] = useState('en')

  useEffect(() => {
    const sessionLanguage = sessionStorage.getItem('qlue-saas-website-language')
    if (sessionLanguage) setLang(sessionLanguage)
  }, [])

  return <Context.Provider value={{ lang, setLang }}>{children}</Context.Provider>
}

Provider.propTypes = propTypes

export function useLanguage() {
  const data = useContext(Context)
  if (!data) throw new Error('cannot using this language from this component')

  return data
}
