import APIConfig from './config'

export default APIConfig
export { default as Register } from './URL/register'
export { default as BlogURL } from './URL/blog'
export { default as PricingURL } from './URL/pricing'
export { default as AuthURL } from './URL/auth'
export { default as TransactionURL } from './URL/transaction'
export { default as AccountURL } from './URL/account'
export { default as BankURL } from './URL/affiliate/bank'
export { default as AffiliateRegisterURL } from './URL/affiliate/register'
export { default as AffiliateAuthURL } from './URL/affiliate/auth'
export { default as AffiliateAnalyticsURL } from './URL/affiliate/analytics'
export { default as AffiliatePayoutsURL } from './URL/affiliate/payouts'
export { default as AffiliateClientsURL } from './URL/affiliate/clients'
export { default as AffiliateProfileURL } from './URL/affiliate/profile'
export { default as AffiliateTransactionURL } from './URL/affiliate/transactions'
