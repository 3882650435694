import { combineReducers } from 'redux'

import register from 'domains/Register/form/stores/reducer'
import { cartReducers as cart } from './cart'
import { tokenDashboard } from './data-token-dashboard'
import { paymentReducers as payment } from './payment'
import { transactionReducers as transaction } from './transaction'

const rootReducer = combineReducers({
  register,
  cart,
  tokenDashboard,
  payment,
  transaction
})

export default rootReducer
