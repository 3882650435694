export const isBrowser = typeof window !== 'undefined'

export const capitalize = (text) => {
  if (typeof text !== 'string') return ''
  return text
    .split(' ')
    .map((eachText) => {
      const lowerCaseText = eachText.toLowerCase()
      return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1)
    })
    .join(' ')
}

const mobileScreen = 767

const tabletScreen = 1023

export const IS_FRAME_MOBILE = `@media only screen and (max-width:${mobileScreen}px)`

export const IS_FRAME_DESKTOP = `@media only screen and (min-width:${tabletScreen}px)`

export const IS_FRAME_TABLET = `@media only screen and (min-width:${mobileScreen}px) and (max-width:${tabletScreen}px)`

export const IS_FRAME_MOBILE_JS = isBrowser ? document.body.offsetWidth <= mobileScreen : 0

export function formatMoney(money = 0, config = {}) {
  const intlConfigs = {
    currency              : config.currency.toUpperCase() || 'IDR',
    style                 : 'currency',
    currencyDisplay       : 'code',
    minimumFractionDigits : 0,
    ...config
  }

  const country = intlConfigs.currency.toUpperCase() === 'IDR' ? 'id-ID' : 'en-US'

  return new Intl.NumberFormat(country, intlConfigs).format(money)
}
