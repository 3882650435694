import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import store from 'store'
import { SET_TOKEN_DASHBOARD } from 'store/types'
import { Provider } from 'react-redux'
import { LANGUAGE_SOURCE, useLanguage } from 'utils/useLanguage'
import { ToastProvider } from 'react-toast-notifications'
import { AuthProvider } from '../../providers/auth-provider'
import { decryptToken } from 'utils/decryptToken'

const TOKEN_NAME = process.env.GATSBY_TOKEN_NAME || 'sessionDashLocal'

export default function WrapperPage({ children }) {
  const { lang } = useLanguage()

  useEffect(() => {
    const dashboardToken = document.cookie.split('; ').find((row) => row.startsWith(TOKEN_NAME))
    if (dashboardToken) {

      const cookieValue = dashboardToken.split('=').pop()
      const decryptedToken = decryptToken(cookieValue)

      store.dispatch({ 
        type      : SET_TOKEN_DASHBOARD, 
        token     : { token: decryptedToken },
        isLoading : false 
      })
    }
  }, [])
  
  return (
    <Provider store={ store }>
      <AuthProvider>
        <ToastProvider 
          placement='bottom-right' 
          autoDismiss={ true }
          autoDismissTimeout='5000'
        >
          <IntlProvider locale={ lang } messages={ LANGUAGE_SOURCE[lang] }>
            { children }
          </IntlProvider>
        </ToastProvider>
      </AuthProvider>
    </Provider>
  )
}
